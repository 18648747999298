import {
  ICondition,
  IProfessionalQualification,
  IProfessionalQualificationAttribute,
  IQualification,
  IQualificationAttribute,
} from '@medely/types';

export type ConditionFormValues = {
  id: number;
  label: string;
  qualifications: Partial<IQualification>[];
};

export const SUPPORTED_INPUT_SOURCES = ['user', 'professional'];

const filterNonUserQualificationAttributes = (
  qualification_attributes?: IQualificationAttribute[],
) => qualification_attributes?.filter((qa) => SUPPORTED_INPUT_SOURCES.includes(qa.input_source));

export const buildPayloadFromConditionForm = (
  formValues: ConditionFormValues,
  atIndex = 0,
  omitNonUserInput = false,
) => {
  const formattedQualification = formValues.qualifications.map((professionalQualification) => ({
    qualification_id: professionalQualification.id,
    professional_qualification_attributes_attributes: buildProfessionalQualificationAttributes(
      omitNonUserInput
        ? filterNonUserQualificationAttributes(professionalQualification.qualification_attributes)
        : professionalQualification.qualification_attributes,
    ),
  }))[atIndex];

  return { professional_qualification: formattedQualification };
};

type ProfessionalQualificationFormValues = {
  qualification: Partial<IQualification>;
};

export const buildPayloadFromQualificationForm = (
  formValues: ProfessionalQualificationFormValues,
) => {
  const formattedQualification = {
    qualification_id: formValues.qualification.id,
    professional_qualification_attributes_attributes: buildProfessionalQualificationAttributes(
      // @ts-ignore
      formValues.qualification.qualification_attributes,
    ),
  };

  return { professional_qualification: formattedQualification };
};

const buildProfessionalQualificationAttributes = (
  qualificationAttributes?: IQualificationAttribute[],
) => {
  const professionalQualificationAttributes: (Partial<IProfessionalQualificationAttribute> & {
    extension?: string;
  })[] = [];
  qualificationAttributes?.forEach((qa) => {
    const isImage = qa.field_type.includes('image');
    if (isImage && !!qa.value?.encodedFile) {
      professionalQualificationAttributes.push({
        qualification_attribute_id: qa.id,
        value: qa.value.encodedFile,
        extension: qa.value.extension,
      });
    } else if (!isImage && !!qa.value) {
      professionalQualificationAttributes.push({
        qualification_attribute_id: qa.id,
        value: qa.value,
      });
    }
  });
  return professionalQualificationAttributes;
};

export const typeformIdFromCondition = (condition: ICondition) => {
  const typeformQualification = condition.qualifications.find(
    (qualification) => qualification.qualification_type === 'typeform',
  );
  if (!typeformQualification) {
    throw new Error('Condition does not have an associated typeform qualification');
  }

  const typeformAttribute = typeformQualification.qualification_attributes.find(
    (attribute) => attribute.field_type === 'external_id',
  );
  if (!typeformAttribute) {
    throw new Error('Qualification is missing external_id attribute');
  }
  return typeformAttribute.data.id;
};

export const typeformIdFromQualification = (qualification: IProfessionalQualification) => {
  const typeformAttribute = qualification?.professional_qualification_attributes?.find(
    (attribute) => attribute?.qualification_attribute?.field_type === 'external_id',
  );
  if (!typeformAttribute) {
    throw new Error('Qualification is missing external_id attribute');
  }
  return typeformAttribute?.value;
};

export const sortQualificationAttributes = (
  a: IQualificationAttribute,
  b: IQualificationAttribute,
) => {
  // Will not change attributes
  // returns -1 if a > b
  // returns 1 if b > a
  // returns 0 if a = b
  const fieldTypes = [
    'license_number',
    'state',
    'expiration_date',
    'boolean',
    'image_front',
    'image_back',
    'pdf',
    'string',
    'date',
  ];
  return a.field_type === b.field_type
    ? a.id - b.id
    : fieldTypes.indexOf(a.field_type) - fieldTypes.indexOf(b.field_type);
};
