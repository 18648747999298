import React from 'react';
import { Box, MultiSelectField, VStack } from '@medely/ui-kit';
import { PositionsDetailsModalButton } from './PositionsDetailsModalButton';
import { IPosition } from '@medely/types';
import { SelectedPosition } from './types';

type PositionsSelectProps = {
  allPositions: IPosition[];
  onSelectAnyPosition: (param: boolean) => void;
};

export const PositionsSelect = ({ allPositions, onSelectAnyPosition }: PositionsSelectProps) => {
  const options = allPositions.map((position) => {
    return {
      value: position.id,
      label: position.display_name,
    };
  });

  return (
    <>
      <Box pt={5}>
        <PositionsDetailsModalButton positions={allPositions} />
        <VStack gap={2}>
          <MultiSelectField
            variant="list"
            options={options}
            label="Selected Positions"
            name="professional_positions.positions"
            testId="positions-select-multi-select"
            parseValue={(value) => {
              return value.map((position: SelectedPosition) => position.id);
            }}
            formatOnChange={(newValue, fieldValue) => {
              const fieldValueCopy = [...fieldValue];
              newValue.forEach((value: number) => {
                if (!fieldValueCopy.some((position: SelectedPosition) => position.id === value)) {
                  fieldValueCopy.push({
                    id: value,
                    years_of_experience: undefined,
                    specialty_ids: [],
                  });
                }
              });
              const returnValue =
                fieldValueCopy.filter((position: SelectedPosition) =>
                  newValue.includes(position.id),
                ) || [];
              onSelectAnyPosition(returnValue.length === 0 ? true : false);
              return returnValue;
            }}
          />
        </VStack>
      </Box>
    </>
  );
};
