import React, { createContext, useContext, ReactNode, useEffect, useState } from 'react';
import useMissingConditionOptions, { ConditionOption } from './useMissingConditionOptions';
import {
  buildPayloadFromConditionForm,
  type ConditionFormValues,
} from '../../../utils/professionalQualifications';
import { useProfessionalQualifications } from '../../../hooks/useProfessionalQualifications';
import useMissingConditionFormValues from './useMissingConditionFormValues';
import type { ICondition } from '@medely/types';
import { useEditProfessionalQualification } from '../../../hooks/useProfessionalQualification';
import { SatisfactionOptionQualification } from '@medely/credentials-tools';

interface IMissingConditionFormProps {
  condition: ICondition;
  children: (props: {
    condition: any;
    isActionable: boolean;
    isSubmitting: boolean;
    submit: (values: { condition: ConditionFormValues }) => Promise<void>;
  }) => ReactNode;
  onQualificationsResolve?: () => void;
}

type MissingConditionFormContext = {
  condition?: ICondition;
  options: ConditionOption[];
  selectedCondition?: ConditionOption['condition'];
  selectedQualification?: SatisfactionOptionQualification;
  selectedQualificationIndex: number;
  selectCondition: (condition: ConditionOption['condition'] | undefined) => void;
};

const MissingConditionFormContext = createContext<MissingConditionFormContext>({
  options: [],
  selectedQualificationIndex: 0,
  selectCondition: () => null,
});

export const useMissingConditionForm = () => useContext(MissingConditionFormContext);

export const MissingConditionForm = ({
  condition,
  children,
  onQualificationsResolve,
}: IMissingConditionFormProps) => {
  const { submitProfessionalQualification, isSubmitting: isSubmittingProfessionalQualification } =
    useProfessionalQualifications();
  const {
    editAsync: editProfessionalQualification,
    isEditing: isEditingProfessionalQualification,
  } = useEditProfessionalQualification();

  const [selectedCondition, setSelectedCondition] = useState<
    ConditionOption['condition'] | undefined
  >(undefined);
  const [selectedQualificationIndex, setSelectedQualificationIndex] = useState<number>(0);
  const isSubmitting = isSubmittingProfessionalQualification || isEditingProfessionalQualification;

  const missingQualifications = useMissingConditionOptions(condition);
  const { values: conditionFormValues, findExistingQualification } =
    useMissingConditionFormValues(selectedCondition);

  const selectedQualification = selectedCondition?.qualifications?.[selectedQualificationIndex];

  const isSingleCondition = missingQualifications.length === 1;
  const isActionable = isSingleCondition || Boolean(selectedQualification);
  const isLastStep =
    !!selectedCondition?.qualifications &&
    selectedQualificationIndex === selectedCondition?.qualifications?.length - 1;

  const submit = async (values: { condition: ConditionFormValues }) => {
    try {
      const existingPq = findExistingQualification(selectedQualification?.qualification);
      const payload = buildPayloadFromConditionForm(
        values.condition,
        selectedQualificationIndex,
        true,
      );

      if (existingPq && !['approved', 'expired'].includes(existingPq.status)) {
        await editProfessionalQualification({
          id: existingPq.id,
          ...payload,
        });
      } else {
        await submitProfessionalQualification(payload);
      }

      if (isLastStep) {
        onQualificationsResolve?.();
      } else {
        setSelectedQualificationIndex((index) => index + 1);
      }
    } catch (error) {
      throw error;
    }
  };

  const selectCondition = (condition: ConditionOption['condition'] | undefined) => {
    if (condition) {
      setSelectedQualificationIndex(0);
    }

    setSelectedCondition(condition);
  };

  useEffect(() => {
    if (!missingQualifications.length) {
      onQualificationsResolve?.();
    } else if (missingQualifications.length === 1) {
      setSelectedCondition(missingQualifications[0].condition);
    }
  }, [missingQualifications.length]);

  return (
    <MissingConditionFormContext.Provider
      value={{
        condition,
        options: missingQualifications,
        selectedCondition,
        selectedQualification,
        selectCondition,
        selectedQualificationIndex,
      }}
    >
      {children({ condition: conditionFormValues, submit, isActionable, isSubmitting })}
    </MissingConditionFormContext.Provider>
  );
};
