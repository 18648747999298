import { type ResultOf } from '@graphql-typed-document-node/core';
import { graphql } from '../graphql/generated';
import { useGraphQLQuery } from './useGraphQLRequest';

const ProfessionalTodosGql = graphql(`
  query GetProfessionalTodos {
    professionalTodos {
      campaigns_status
      available_for_extensions
      active_jobs_requesting_to_work_facility_ids
      has_position_with_completed_onboarding
      assignments_missing_conditions
      assignment_offers
      extension_offers
      days_until_debit_card_expires
      booked_jobs_count
      finished_jobs_count
      unsigned_addendum_assignment_ids
      missing_premier_credentials
      missing_w2_per_diem_onboarding
      disputes_waiting_on_professional
      pending_pro_pool_invites {
        professional_qualification_id
        location_id
        location_name
      }
      pending_pro_pool_onboarding {
        location_id
        location_name
      }
    }
  }
`);

export type UseProfessionalTodosOptions = {
  enabled?: boolean;
};

type ProfessionalTodosResult = ResultOf<typeof ProfessionalTodosGql>;
// eslint-disable-next-line medely/noCommentWithoutTicket -- Lint rule mismatch
// ProfessionalTodos include some JSON fields that need to be manually typed
type RawProfessionalTodos = ProfessionalTodosResult['professionalTodos'];
export type ProfessionalTodos =
  | null
  | undefined
  | (Omit<
      NonNullable<RawProfessionalTodos>,
      'pending_pro_pool_invites' | 'pending_pro_pool_onboarding' | '__typename'
    > & {
      pending_pro_pool_invite: PendingProPoolInvite | null;
      pending_pro_pool_onboarding: PendingProPoolOnboarding | null;
    });

export function useProfessionalTodos(options: UseProfessionalTodosOptions = {}) {
  const { enabled } = options;

  const { data, isLoading, isInitialLoading, refetch } = useGraphQLQuery<ProfessionalTodosResult>({
    operationName: 'GetProfessionalTodos',
    query: ProfessionalTodosGql,
    variables: {},
    enabled,
  });

  const professionalTodos = data?.professionalTodos;
  const todos: ProfessionalTodos | undefined = professionalTodos && {
    ...professionalTodos,
    pending_pro_pool_invite: parsePendingProPoolInvites(professionalTodos.pending_pro_pool_invites),
    pending_pro_pool_onboarding: parsePendingProPoolOnboarding(
      professionalTodos.pending_pro_pool_onboarding,
    ),
  };

  return {
    todos,
    isLoading: enabled ? isLoading : isInitialLoading,
    refetch,
  };
}

function parsePendingProPoolInvites(invites: any[]): PendingProPoolInvite | null {
  try {
    // TODO: Validate with yup or zod
    return invites.length ? invites[0] : null;
  } catch (e) {
    // TODO(PRO-3675): Multi-platform rollbar
    console.error('Failed to parse pending pro pool invites', e);
  }

  return null;
}

function parsePendingProPoolOnboarding(onboarding: any[]): PendingProPoolOnboarding | null {
  try {
    // TODO: Validate with yup or zod
    return onboarding.length ? onboarding[0] : null;
  } catch (e) {
    // TODO(PRO-3675): Multi-platform rollbar
    console.error('Failed to parse pending pro pool onboarding', e);
  }

  return null;
}

export type PendingProPoolInvite = {
  professional_qualification_id: number;
  location_id: number;
  location_name: string;
};

export type PendingProPoolOnboarding = {
  location_id: number;
  location_name: string;
};

export default useProfessionalTodos;
