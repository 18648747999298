import React, { ReactElement } from 'react';
import { centsToCurrency } from '../../../../utils/centsToCurrency';
import { ILocation } from '@medely/types';
import { HStack, Icon, Text, TextProps, Box } from '@medely/ui-kit';
import { useCurrentUser } from '../../../../hooks/useCurrentUser';
import { JobDetailsListItem } from '../../JobDetailsListItem';

type ParkingInfoSectionProps = {
  parkingDetails: Partial<ILocation>;
  bookedOrInProgress: boolean;
  smallIcon?: boolean;
};

type ParkingInformationItemProps = {
  text: TextProps['children'];
};

const ParkingInformationItem = ({ text }: ParkingInformationItemProps) => {
  return (
    <HStack my={2} gap={1}>
      <Icon name="check" iconSize="20px" />
      <Text size="m">{text}</Text>
    </HStack>
  );
};

const ParkingInfoSection = ({
  parkingDetails,
  bookedOrInProgress,
  smallIcon = false,
}: ParkingInfoSectionProps): ReactElement => {
  const {
    parking_instructions,
    parking_cost_cents,
    parking_reimbursed,
    parking_reimbursement_cents,
    parking_free,
    parking_validated,
  } = parkingDetails;
  const { isMarketplace } = useCurrentUser();
  const showParkingReimbursement = parking_reimbursed && isMarketplace;
  const parkingReimbursementCentsText = !!parking_reimbursement_cents
    ? `(${centsToCurrency(parking_reimbursement_cents)})`
    : '';

  const hasNoParkingDetails = Object.values(parkingDetails).some((value) => !value);

  if (hasNoParkingDetails) {
    return <></>;
  }

  return (
    <JobDetailsListItem
      title="Parking"
      icon={
        <Icon
          name="light-parking-circle"
          iconSize={smallIcon ? '14px' : '20px'}
          color="text.primary"
          variant="custom"
        />
      }
    >
      <Box mt={1}>
        <Text size="m" color="text.secondary" testId="job-additional-details-parking-instructions">
          {bookedOrInProgress && parking_instructions}
        </Text>
        {!!parking_cost_cents && (
          <ParkingInformationItem text={`Parking cost (${centsToCurrency(parking_cost_cents)})`} />
        )}
        {showParkingReimbursement && (
          <ParkingInformationItem text={`Parking reimbursed ${parkingReimbursementCentsText}`} />
        )}

        {parking_free && <ParkingInformationItem text="Free parking on premises" />}
        {parking_validated && <ParkingInformationItem text="Parking validated" />}
      </Box>
    </JobDetailsListItem>
  );
};

export default ParkingInfoSection;
