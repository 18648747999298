import { useAnalytics } from '@medely/ui-kit';
import { useCallback } from 'react';
import { useCurrentUser } from '../useCurrentUser';

export function useClockInOutEvent() {
  const analytics = useAnalytics();
  const { currentUser } = useCurrentUser();
  const professionalId = currentUser?.professional?.id?.toString();

  const trackStartShiftInJobDetails = useCallback(
    (jobId: number) => {
      analytics.track('Job Details - Start Shift Button - Clicked', {
        context: 'Job Details',
        subcontext: 'Start Shift Button',
        action: 'Clicked',
        professional_id: professionalId,
        job_id: jobId,
        impersonating: false,
      });
    },
    [analytics],
  );

  const trackStartShiftInToday = useCallback(
    (jobId: number) => {
      analytics.track('Today - Start Shift Button - Clicked', {
        context: 'Today',
        subcontext: 'Start Shift Button',
        action: 'Clicked',
        professional_id: professionalId,
        job_id: jobId,
        impersonating: false,
      });
    },
    [analytics],
  );

  const trackShareLocationTakePhotoNextButton = useCallback(
    (jobId: number) => {
      analytics.track('Share Location Take Photo - Continue Button - Clicked', {
        context: 'Share Location Take Photo',
        subcontext: 'Continue Button',
        action: 'Clicked',
        professional_id: professionalId,
        job_id: jobId,
        impersonating: false,
      });
    },
    [analytics],
  );

  const trackStartShiftConfirm = useCallback(
    (jobId: number) => {
      analytics.track('Start Shift - Confirm Button - Clicked', {
        context: 'Start Shift',
        subcontext: 'Confirm Button',
        action: 'Clicked',
        professional_id: professionalId,
        job_id: jobId,
        impersonating: false,
      });
    },
    [analytics],
  );

  const trackEndShiftInJobDetails = useCallback(
    (jobId: number) => {
      analytics.track('Job Details - End Shift Button - Clicked', {
        context: 'Job Details',
        subcontext: 'End Shift Button',
        action: 'Clicked',
        professional_id: professionalId,
        job_id: jobId,
        impersonating: false,
      });
    },
    [analytics],
  );

  const trackEndShiftInToday = useCallback(
    (jobId: number) => {
      analytics.track('Today - End Shift Button - Clicked', {
        context: 'Today',
        subcontext: 'End Shift Button',
        action: 'Clicked',
        professional_id: professionalId,
        job_id: jobId,
        impersonating: false,
      });
    },
    [analytics],
  );

  const trackTimesheetNextButton = useCallback(
    (jobId: number) => {
      analytics.track('Timesheet Requirement - Next Button - Clicked', {
        context: 'Timesheet Requirement',
        subcontext: 'Next Button',
        action: 'Clicked',
        professional_id: professionalId,
        job_id: jobId,
        impersonating: false,
      });
    },
    [analytics],
  );

  //The endShiftConfirm event is located on trackClockOut

  return {
    trackStartShiftInJobDetails,
    trackStartShiftInToday,
    trackShareLocationTakePhotoNextButton,
    trackStartShiftConfirm,
    trackEndShiftInJobDetails,
    trackEndShiftInToday,
    trackTimesheetNextButton,
  };
}
